











































import SKPosition from '@/components/SK-Position.vue';
import { IPosition } from '@/utils/interfaces';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKPosition
  }
})
export default class TrendingPositions extends Vue {
  get trendingPositions(): IPosition[] {
    return this.$store.getters.trending;
  }
}
